//
//  App
//

.app {
	display: flex;
	flex: 1 0 auto;
	// flex-direction: column;
	// Mobile Status
	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		// overflow: hidden;
	}
}

.search-modal {
}
.modal-dialog {
	width: 1000px;
}
