@use 'sass:map';
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: 'OpenSansRegular';
	scroll-behavior: smooth;
}

@font-face {
	font-family: 'OpenSansBold';
	src: url('../../fonts/OpenSans-Bold.ttf');
}

@font-face {
	font-family: 'OpenSansBoldItalic';
	src: url('../../fonts/OpenSans-BoldItalic.ttf');
}

@font-face {
	font-family: 'OpenSansLight';
	src: url('../../fonts/OpenSans-Light.ttf');
}

@font-face {
	font-family: 'OpenSansRegular';
	src: url('../../fonts/OpenSans-Regular.ttf');
}

.fwi-content {
	.h1 {
		font-size: 20 font-weight 700;
	}

	.title {
		@include font-size(1.5rem);
		font-weight: 700;
	}

	.description {
		font-weight: 300;
		color: black;
	}

	.label {
		@include font-size(1rem);
	}
}

legend {
	font-size: 0.9rem;
}

.cleartext {
	font-weight: 100;
}

table td.fit,
table th.fit {
	white-space: nowrap;
	width: 1%;
}

.folder-card {
	flex-direction: row;
	display: flex;
}
