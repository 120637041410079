//
//  Content
//
@use 'sass:map';
@use 'sass:math';

.content {
	@include padding(map-get($content, padding-y) map-get($content, padding-x));

	display: flex;
	flex: 1 0 auto;

	.header + & {
		@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
			padding-top: map-deep-get($header, mobile, height);
		}
	}

	border-radius: 20px !important;

	background-color: #ebeff8;
	color: if(
		map.get($content, color),
		map.get($content, color),
		color-contrast(map.get($content, background-color), map.get($content, color-dark))
	);

	@include admin-mode {
		border-radius: 0px !important;
	}

	@include dark-mode {
		background-color: rgba(
			map.get($content, dark-background-color),
			map.get($content, opacity)
		);
		color: if(
			map.get($header, color),
			color-contrast(map.get($content, dark-background-color), map.get($content, color-dark)),
			map.get($header, color)
		);
	}
}
