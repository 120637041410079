//
//	Modal
//

.modal-content {
	@include dark-mode {
		background-color: $dark-modal-content-bg;
	}
}

.modal {
	border: 1px solid #345abc;
	&-backdrop {
		background-color: rgba(255, 255, 255, 0.5);

		&.show {
			opacity: 1;
		}
	}
}
